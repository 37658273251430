import React from 'react'
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Config from '../config/config';
import { Typography, Stack } from '@mui/material';



const CartItemPedidos = ({ cartItem, removeFromCart }) => {
    return (
        <div className="row mt-3 no-gutters">
            <div className="col-md-3">
                {cartItem.images.length > 1 ? (
                    <img width={80} height={80} src={`${Config.base_fotos}/thumb/${cartItem.produto}_${cartItem.cor_produto}_01.jpg`} class="card-img-top" />
                ) : (
                    <img width={80} height={80} src={`https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg`} class="card-img-top" style={{ padding: 10 }} />
                )}

            </div>
            <div className="col-md-9">
                <div style={{ fontSize: 12, paddingBottom: 5, fontWeight: 'bold' }} className="mt-2">{cartItem.use_des}</div>
            </div>
            <div className="col-md-12">
                <Stack direction={"column"}>
                    <Stack>
                        <Typography>{cartItem?.name}</Typography>
                    </Stack>
                    <Stack spacing={1}>
                        <Typography style={{ fontSize: 12, fontWeight: 'bold' }} className="mt-2"></Typography>
                        <Typography>Referência: <strong>{cartItem?.use_referencia}</strong></Typography>
                        <Stack spacing={1} direction={"row"}>
                            <Typography>Cod Cor: <strong>{cartItem?.use_cod_cor_produto}</strong></Typography>
                            <Typography>Cor: <strong>{cartItem?.use_cor_produto}</strong></Typography>
                        </Stack>
                        <Stack spacing={1} direction={"row"}>
                            <Typography>loja: <strong>{cartItem?.filial}</strong></Typography>
                            <Typography>tamanho: <strong>{cartItem?.grade}</strong></Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </div>
        </div>

    )
}

export default CartItemPedidos;
