import React from 'react'
import { Grid, Box, Typography, Stack, Button, IconButton } from "@mui/material";
import Config from '../config/config'
import Iconify from './Iconify';
// extends
import ItemWithLabel from './extends/ItemWithLabel';

const CartItemResumo = ({ cartItem, removeFromCart, addItemQty, removeItemQty }) => {
    const trasnformPrice = parseFloat(cartItem.regular_price) * 0.9
    console.log(cartItem)
    return (
        <Box sx={{ borderBottom: '1px dashed #ccc', pb: 2 }}>
            <Stack spacing={1} direction={{ xs: 'column', sm: 'column', md:'column', lg:'row' }}>
                <Stack direction={"row"}>
                    <Box
                        component={"img"}
                        className="rounded"
                        sx={{ width: 80, height: 80, padding: 0.5 }}
                        src={cartItem.images.length > 0 ? `${Config.base_fotos}/thumb/${cartItem.produto}_${cartItem.cor_produto}_01.jpg` : 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg'} alt=""
                    />
                </Stack>


                <Grid container spacing={1}>
                    <Grid item md={12} sm={12}>
                        <Stack alignItems={"center"} direction={"row"}>
                            <IconButton
                                style={{ color: "grey", p:0 }}
                                onClick={() => removeFromCart(cartItem)}
                            >
                            <Iconify width={30} icon="mdi:delete" />
                            </IconButton>
                            <Typography sx={{ fontSize: 14 }}>{cartItem.use_descricao}</Typography>

                        </Stack>

                    </Grid>

                    <Grid xs={12} sm={12} item md={4}>
                        <ItemWithLabel label={'Referencia'} value={cartItem.use_referencia} />
                    </Grid>

                    <Grid xs={12} sm={12} item md={4} >
                        <ItemWithLabel label={'Preço'} value={trasnformPrice.toFixed(2)} />
                    </Grid>

                    <Grid xs={12} sm={12} item md={4} >
                        <ItemWithLabel label={'Loja'} value={cartItem.filial} />
                    </Grid>
                    <Grid xs={12} sm={12} item md={4} >
                        <ItemWithLabel label={'Tamanho'} value={cartItem.grade} />
                    </Grid>
                    <Grid xs={6} sm={6} item md={4} >
                        <ItemWithLabel label={'Cod cor'} value={cartItem.use_cod_cor_produto} />
                    </Grid>
                    <Grid xs={12} sm={12} item md={8} >
                        <ItemWithLabel label={'Desc Cor'} value={cartItem.use_cor_produto} />
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    )
}

export default CartItemResumo
