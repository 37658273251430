export function validaParcelas(valorTotal) {
  if (valorTotal < 60) {
    return {
      parcelas: [
        {
          quantidade: 1,
          valor: parseFloat(valorTotal / 1).toFixed(2)
        }
      ]
    }
  } else if (valorTotal >= 60 && valorTotal < 90) {
    return {
      parcelas: [
        {
          quantidade: 1,
          valor: parseFloat(valorTotal / 1).toFixed(2)
        },
        {
          quantidade: 2,
          valor: parseFloat(valorTotal / 2).toFixed(2)
        }
      ]
    }

  } else if (valorTotal >= 90 && valorTotal < 120) {
    return {
      parcelas: [
        {
          quantidade: 1,
          valor: parseFloat(valorTotal / 1).toFixed(2)
        },
        {
          quantidade: 2,
          valor: parseFloat(valorTotal / 2).toFixed(2)
        },
        {
          quantidade: 3,
          valor: parseFloat(valorTotal / 3).toFixed(2)
        }
      ]
    }
  } else if (valorTotal >= 120 && valorTotal < 150) {
    return {
      parcelas: [
        {
          quantidade: 1,
          valor: parseFloat(valorTotal / 1).toFixed(2)
        },
        {
          quantidade: 2,
          valor: parseFloat(valorTotal / 2).toFixed(2)
        },
        {
          quantidade: 3,
          valor: parseFloat(valorTotal / 3).toFixed(2)
        },
        {
          quantidade: 4,
          valor: parseFloat(valorTotal / 4).toFixed(2)
        }
      ]
    }
  } else if (valorTotal >= 150 && valorTotal < 180) {
    return {
      parcelas: [
        {
          quantidade: 1,
          valor: parseFloat(valorTotal / 1).toFixed(2)
        },
        {
          quantidade: 2,
          valor: parseFloat(valorTotal / 2).toFixed(2)
        },
        {
          quantidade: 3,
          valor: parseFloat(valorTotal / 3).toFixed(2)
        },
        {
          quantidade: 4,
          valor: parseFloat(valorTotal / 4).toFixed(2)
        },
        {
          quantidade: 5,
          valor: parseFloat(valorTotal / 5).toFixed(2)
        }
      ]
    }
  } else if (valorTotal >= 180 && valorTotal < 210) {
    return {
      parcelas: [
        {
          quantidade: 1,
          valor: parseFloat(valorTotal / 1).toFixed(2)
        },
        {
          quantidade: 2,
          valor: parseFloat(valorTotal / 2).toFixed(2)
        },
        {
          quantidade: 3,
          valor: parseFloat(valorTotal / 3).toFixed(2)
        },
        {
          quantidade: 4,
          valor: parseFloat(valorTotal / 4).toFixed(2)
        },
        {
          quantidade: 5,
          valor: parseFloat(valorTotal / 5).toFixed(2)
        },
        {
          quantidade: 6,
          valor: parseFloat(valorTotal / 6).toFixed(2)
        }
      ]
    }

  } else if (valorTotal >= 210 && valorTotal < 240) {
    return {
      parcelas: [
        {
          quantidade: 1,
          valor: parseFloat(valorTotal / 1).toFixed(2)
        },
        {
          quantidade: 2,
          valor: parseFloat(valorTotal / 2).toFixed(2)
        },
        {
          quantidade: 3,
          valor: parseFloat(valorTotal / 3).toFixed(2)
        },
        {
          quantidade: 4,
          valor: parseFloat(valorTotal / 4).toFixed(2)
        },
        {
          quantidade: 5,
          valor: parseFloat(valorTotal / 5).toFixed(2)
        },
        {
          quantidade: 6,
          valor: parseFloat(valorTotal / 6).toFixed(2)
        },
        {
          quantidade: 7,
          valor: parseFloat(valorTotal / 7).toFixed(2)
        }
      ]
    }

  } else if (valorTotal >= 240 && valorTotal < 270) {
    return {
      parcelas: [
        {
          quantidade: 1,
          valor: parseFloat(valorTotal / 1).toFixed(2)
        },
        {
          quantidade: 2,
          valor: parseFloat(valorTotal / 2).toFixed(2)
        },
        {
          quantidade: 3,
          valor: parseFloat(valorTotal / 3).toFixed(2)
        },
        {
          quantidade: 4,
          valor: parseFloat(valorTotal / 4).toFixed(2)
        },
        {
          quantidade: 5,
          valor: parseFloat(valorTotal / 5).toFixed(2)
        },
        {
          quantidade: 6,
          valor: parseFloat(valorTotal / 6).toFixed(2)
        },
        {
          quantidade: 7,
          valor: parseFloat(valorTotal / 7).toFixed(2)
        },
        {
          quantidade: 8,
          valor: parseFloat(valorTotal / 8).toFixed(2)
        }
      ]
    }

  } else if (valorTotal >= 270 && valorTotal < 300) {
    return {
      parcelas: [
        {
          quantidade: 1,
          valor: parseFloat(valorTotal / 1).toFixed(2)
        },
        {
          quantidade: 2,
          valor: parseFloat(valorTotal / 2).toFixed(2)
        },
        {
          quantidade: 3,
          valor: parseFloat(valorTotal / 3).toFixed(2)
        },
        {
          quantidade: 4,
          valor: parseFloat(valorTotal / 4).toFixed(2)
        },
        {
          quantidade: 5,
          valor: parseFloat(valorTotal / 5).toFixed(2)
        },
        {
          quantidade: 6,
          valor: parseFloat(valorTotal / 6).toFixed(2)
        },
        {
          quantidade: 7,
          valor: parseFloat(valorTotal / 7).toFixed(2)
        },
        {
          quantidade: 8,
          valor: parseFloat(valorTotal / 8).toFixed(2)
        },
        {
          quantidade: 9,
          valor: parseFloat(valorTotal / 9).toFixed(2)
        }
      ]
    }

  } else if (valorTotal >= 300) {
    return {
      parcelas: [
        {
          quantidade: 1,
          valor: parseFloat(valorTotal / 1).toFixed(2)
        },
        {
          quantidade: 2,
          valor: parseFloat(valorTotal / 2).toFixed(2)
        },
        {
          quantidade: 3,
          valor: parseFloat(valorTotal / 3).toFixed(2)
        },
        {
          quantidade: 4,
          valor: parseFloat(valorTotal / 4).toFixed(2)
        },
        {
          quantidade: 5,
          valor: parseFloat(valorTotal / 5).toFixed(2)
        },
        {
          quantidade: 6,
          valor: parseFloat(valorTotal / 6).toFixed(2)
        },
        {
          quantidade: 7,
          valor: parseFloat(valorTotal / 7).toFixed(2)
        },
        {
          quantidade: 8,
          valor: parseFloat(valorTotal / 8).toFixed(2)
        },
        {
          quantidade: 9,
          valor: parseFloat(valorTotal / 9).toFixed(2)
        },
        {
          quantidade: 10,
          valor: parseFloat(valorTotal / 10).toFixed(2)
        }
      ]
    }

  }

}

export const getValorTotal = (cartItems, valorFrete, desconto, ajuste) => {
  console.log(cartItems, valorFrete,desconto, ajuste)
  if (cartItems.length === 0) return 0;
  let valorTotalGeral;

  const subtotal = parseFloat(cartItems.reduce((acc, { regular_price, quantity_inc }) => {
    let qty = 1;
    let item = parseFloat(regular_price).toFixed(2) * 0.9
    let accumulator = parseFloat(acc).toFixed(2)
    let res = parseFloat(item) * qty + parseFloat(accumulator) - desconto + ajuste;
    return parseFloat(res).toFixed(2);
  }, 0));

  valorTotalGeral = subtotal + valorFrete - desconto + ajuste;
  return valorTotalGeral.toFixed(2);
}


export const getValorTotal2 = (dataOrder) => {
  const { items, shipping_price, desconto, ajuste } = dataOrder;
  console.log(dataOrder)
  let valorTotalGeral;

  if (items.length === 0) return 0;
  const subtotal = parseFloat((dataOrder.items).reduce((acc, { regular_price, quantity_inc }) => {
    let qty = 1;
    let item = parseFloat(regular_price).toFixed(2) * 0.9
    let accumulator = parseFloat(acc).toFixed(2)
    let res = parseFloat(item) * qty + parseFloat(accumulator);
    return parseFloat(res).toFixed(2);
  }, 0));

  valorTotalGeral = subtotal + shipping_price - desconto + ajuste;
  return valorTotalGeral.toFixed(2);
}




export const getValorSubTotal = (cartItems) => {
  const subtotal = parseFloat(cartItems.reduce((acc, { regular_price, quantity_inc }) => {
    let qty = 1;
    let item = parseFloat(regular_price).toFixed(2) * 0.9
    let accumulator = parseFloat(acc).toFixed(2)
    let res = parseFloat(item) * qty + parseFloat(accumulator);
    return parseFloat(res).toFixed(2);
  }, 0))
  return subtotal;
}

export const maiorComprimentoItems = (items) => {
  let array = items.map(item => item.width);
  let result = Math.max.apply(null, array);
  return result;
}

export const alturaCalculado = (data) => {
  const valorTotal = parseFloat(data.reduce((acc, { heigth }) => {
    let qty = 1;
    let item = parseFloat(heigth).toFixed(2)
    let accumulator = parseFloat(acc).toFixed(2)
    let res = parseFloat(item) + parseFloat(accumulator)
    return parseFloat(res).toFixed(2);
  }, 0))
  return valorTotal;
}

export const maiorLarguraItems = (items) => {
  let array = items.map(item => item.length);
  let result = Math.max.apply(null, array);
  return result;
}

export const pesoCalculado = (data) => {
  const valorTotal = parseFloat(data.reduce((acc, { weight }) => {
    let item = parseFloat(weight).toFixed(2)
    let accumulator = parseFloat(acc).toFixed(2)
    let res = parseFloat(item) + parseFloat(accumulator)
    return parseFloat(res).toFixed(2);
  }, 0))
  return valorTotal;
}

export const soNumeros = (string) => {
  var numbers = string.replace(/[^0-9]/g, '')
  return numbers;
}


export const maskCPF = value => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};

export function mascararTel(v) {
  v = v.replace(/\D/g, "");
  v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
  v = v.replace(/(\d)(\d{4})$/, "$1-$2");
  return v;
}

export const maskDate = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1");
};


export const validarCPF = (cpf) => {
  let i;
  let rev;
  let add;
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf == '') return false;
  // Elimina CPFs invalidos conhecidos	
  if (cpf.length != 11 ||
    cpf == "00000000000" ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999")
    return false;
  // Valida 1o digito	
  add = 0;
  for (i = 0; i < 9; i++)
    add += parseInt(cpf.charAt(i)) * (10 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11)
    rev = 0;
  if (rev != parseInt(cpf.charAt(9)))
    return false;
  // Valida 2o digito	
  add = 0;
  for (i = 0; i < 10; i++)
    add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11)
    rev = 0;
  if (rev != parseInt(cpf.charAt(10)))
    return false;
  return true;
}

export function validaStatus(string) {
  if (string === "60774fa684e6861cb41a472f") {
    return "Pendente Pagamento";
  } else if (string === "6143439585ee0369a5dfacbc") {
    return "Cancelado";
  } else if (string === "60774f9c84e6861cb41a472e") {
    return "Pedido Feito";
  } else if (string === "6143412285ee0369a5dfacb4") {
    return "Aceito";
  } else if (string === "60774fb684e6861cb41a4730") {
    return "Pag. Aprovado";
  } else if (string === "6143414c85ee0369a5dfacb5") {
    return "Expedição";
  } else if (string === "630d0625d8c7d3d0ea112058") {
    return "Backoffice";
  } else if (string === "6143432885ee0369a5dfacb9") {
    return "Em trânsito";
  } else if (string === "6143434b85ee0369a5dfacba") {
    return "Retirda Disponínvel";
  }
}

export const phoneMask = (v) => {

  let r = v.replace(/\D/g, "");
  r = r.replace(/^0/, "");

  if (r.length > 11) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (r.length > 7) {
    r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  } else if (v.trim() !== "") {
    r = r.replace(/^(\d*)/, "($1");
  }
  return r;
}


export const notifyMe = (content) => {
  // Verifica se o browser suporta notificações
  if (!("Notification" in window)) {
    alert("Este browser não suporta notificações de Desktop");
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === "granted") {
    const notification = new Notification('VENDA FÁCIL', {
      body: content
    });
  }

  else if (Notification.permission !== 'denied') {
    Notification.requestPermission(function (permission) {
      if (permission === "granted") {
        const notification = new Notification('VENDA FÁCIL', {
          body: content
        });
      }
    });
  }

}

export function transformStringToBase64(string) {
  const encodedString = Buffer.from(string).toString('base64');
  return encodedString;
}


export function validaTotalGeral(cartItems, valorFrete, valorDesconto) {
  if (cartItems.length === 0) return 0;
  let valorTotalGeral;

  let subtotal = parseFloat(cartItems.reduce((acc, { regular_price, quantity_inc }) => {
      let qty = 1;
      let item = parseFloat(regular_price).toFixed(2) * 0.9
      let accumulator = parseFloat(acc).toFixed(2)
      let res = parseFloat(item) * qty + parseFloat(accumulator);
      return parseFloat(res).toFixed(2)
  }, 0));

  valorTotalGeral = subtotal + valorFrete - valorDesconto;
  return valorTotalGeral.toFixed(2);
}


export const formatarCodigoFilial = (codigo) => {
  return String(codigo).padStart(6, '0');
}
