import React, { useEffect, useState, useRef } from 'react'
import { withRouter, useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  LinearProgress,
  Box,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Stack,
  Container
} from '@mui/material';
import listaProdutosExclusivos from "../../actions/produtos/lista-produtos-exclusivos";
import Products from "../../components/Products";
import { useSelector, useDispatch } from 'react-redux';
import Actions from "../../constants/Actions";
import Slide from '@material-ui/core/Slide';

import useResponsive from '../../utils/useResponsive';
import { FaSearch } from 'react-icons/fa';
import v2_actionPesquisarProdutos from '../../actions/v2/produtos/v2_actionPesquisarProdutos';
import { useHistory } from 'react-router-dom';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



const ITEM_HEIGHT = 68;
const ITEM_PADDING_TOP = 8;

function HomePage() {


  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const loading = useSelector(state => state.loading);

  const [grupoInput, setGrupoInput] = useState([]);
  const [grupo, setGrupo] = useState([]);

  const [griffe, setGriffe] = useState([]);
  const [griffeInput, setGriffeInput] = useState([]);

  const [filial, setFilial] = useState([]);

  const [subgrupo, setSubgrupo] = useState([]);
  const [subgrupoInput, setSubgrupoInput] = useState([]);

  const [pathOld, setPathOld] = useState("");
  const exclusivo = 'chuteiras';
  const isDesktop = useResponsive('up', 'md');

  const [search, setSearch] = useState('');

  const history = useHistory();


  const carouselRef = useRef(null);

  const settings = {
    dots: true,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1
  };

  const renderSlides = () =>
    [1, 2, 3, 4, 5, 6, 7, 8].map(num => (
      <div>
        <h3>Slide {num}</h3>
      </div>
    ));





  //params


  useEffect(() => {
    if (pathOld !== exclusivo) {
      setSubgrupo([]);
      setSubgrupoInput([]);
      setGriffe([]);
      setGriffeInput([]);
      setGrupo([]);
      setGrupoInput([]);
    }

  }, [exclusivo]);


  // useEffect(() => {
  //   dispatch({ type: Actions.LOADING, loading: true })
  //   listaProdutosExclusivos({ atributo: exclusivo }).then(res => {
  //     if (res.data.produtos.length > 0) {
  //       dispatch({ type: Actions.SET_TODOS_PRODUTOS, products: res.data.produtos });
  //       dispatch({ type: Actions.LOADING, loading: false })
  //     }

  //     dispatch({ type: Actions.LOADING, loading: false })
  //   }).catch(err => console.log(err));

  // }, []);


  const handlePesquisarProdutos = async () => {
    dispatch({ type: Actions.LOADING_PAGE, loadingPage: true });
    history.push('/pdv/busca');
    let querys = `?search=${search}`;
    const response = await v2_actionPesquisarProdutos(querys);
    if(response.status === 200){
      dispatch({ type: Actions.SET_TODOS_PRODUTOS, products: response.data.produtos });
      dispatch({ type: Actions.SEARCH, search: search });
      dispatch({ type: Actions.LOADING_PAGE, loadingPage: false })
    }

  }


  
  const handleKeyUp = e => {
    if (e.key === 'Enter') {
      handlePesquisarProdutos();
    }
  };

  const list = [
  {

  }
  ]



  return (
    <Container>
    <Box sx={{display:'flex', width:'100%', height:'90vh', justifyContent:'center', alignItems:'center'}}>
      <Stack spacing={2} direction={"column"} sx={{width:'100%'}}>
    <Typography variant='h4'>Faça sua pesquisa no venda fácil</Typography>
      <OutlinedInput
      id="outlined-basic"
      fullWidth
      size="small"
      style={{ height: 100, border: '1px solid #000', width:'90%', fontSize: 30 }}
      placeholder='Buscar por referencia ou nome'
      type="search"
      variant="outlined"
      onKeyUp={e => handleKeyUp(e)}
      onChange={e => setSearch(e.target.value)}
      endAdornment={
        <InputAdornment position="end">
          <IconButton onClick={handlePesquisarProdutos}>
            <FaSearch />
          </IconButton>
        </InputAdornment>
      }
    />
      </Stack>

</Box>
</Container>
  )
}
export default withRouter(HomePage)
