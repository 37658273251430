import React, { useEffect } from 'react'
import {Container} from '@material-ui/core';

// páginas
import LinhaPage from '../Page/LinhaPage'
import ExclusivoPage from '../Page/ExclusivoPage';


const Exclusivos = ({search}) => {

  return(
    <div style={{background:"#f9f9f9"}}> 
    <Container maxWidth="xl">
    {/* <ExclusivoPage /> */}
    </Container>
           
    </div>
  )
}

export default Exclusivos;
