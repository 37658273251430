import axios from "axios";
import Config from "../../../config/config";

const v2_actionProdutosLojas = (querys) => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.api_dev : Config.api_prod}/api/v2/apps/pdv/produtos/lojas${querys ? querys : ''}`
    });
};

export default v2_actionProdutosLojas;
