import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { Route, Redirect, useParams } from "react-router-dom";

import './Dashboard.css'

import { ThemeProvider } from 'styled-components';
import Iconify from '../../components/Iconify'
import ChatBot from 'react-simple-chatbot';

import {
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  Box,
  Typography,
  Divider,
  Menu,
  IconButton,
  Badge,
  MenuList,
  MenuItem,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Container,
  Button,
  OutlinedInput,
  InputAdornment,
  TextField,
  CircularProgress,
  Tabs,
  Tab,
  Stack,
  DialogContent
} from '@mui/material';

import ReactPlayer from 'react-player/lazy'


import { useStyles } from '../Style/StyleDashboard';

import Logo from "../../images/logo3d.png";

import Shipping from '../Main/Frete';
import Order from '../Main/Order';
import Introduction from '../Content/Introduction';
import Cadastro from '../Main/Cadastro';
import Pagamento from '../Main/Pagamento';
import Resumo from '../Main/Resumo';
import Conclusao from '../Main/Conclusao';
import Pedidos from '../Main/Pedidos';
import Vendas from "../Main/Vendas";
import Linha from "../Main/Linha";
import Lojas from "../Main/Lojas";
import Exclusivos from "../Main/Exclusivos";
import Suporte from "../Main/Suporte"
import Cart from '../../components/Cart';
import CardMenuCat from '../../components/Home/CardMenuCat';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import useResponsive from '../../utils/useResponsive';
import Actions from "../../constants/Actions";
import { HiShoppingCart } from 'react-icons/hi';
import { BsGrid, BsCart3, BsChevronDoubleDown } from "react-icons/bs";
import { BiHelpCircle } from "react-icons/bi";
import { FaStar } from "react-icons/fa";
import LoadingModal from '../../components/LoadingModal';
import { FaSearch } from "react-icons/fa";
// actions
import actionPesquisarProdutos from '../../actions/produtos/pesquisar-produtos';
// actions
import listaProdutosPorLinha from '../../actions/produtos/lista-produtos-por-linha'
import listaProdutosExclusivos from '../../actions/produtos/lista-produtos-exclusivos';
import AdicionarProdutoBySync from '../../components/Chatbot/AdicionarProduto';
import getListaPedidosByStatus2 from '../../actions/relatorios/lista-pedidos-by-status-2';
import { getFilialUser } from "../../services/auth";
import { notifyMe } from '../../utils/functions';
import DrawerMenuCategorias from '../../components/DrawerMenuCategorias';
import v2_actionPesquisarProdutos from '../../actions/v2/produtos/v2_actionPesquisarProdutos';

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { linha } = useParams();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const drawerCarrinho = useSelector(state => state.drawerCarrinho);
  const loadingPage = useSelector(state => state.loadingPage);
  const cartItems = useSelector(state => state.cartItems);
  const [token] = useState(localStorage.getItem("jwt"))
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");

  const [openDialogSuporte, setOpenDialogSuporte] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);


  const [anchorElEspeciais, setAnchorElEspeciais] = useState(null);
  const [anchorElLojas, setAnchorElLojas] = useState(null);

  const isDesktop = useResponsive('up', 'md');

  const theme = {
    background: '#f5f8fb',
    fontFamily: 'Helvetica Neue',
    headerBgColor: '#EF6C00',
    headerFontColor: '#fff',
    headerFontSize: '15px',
    botBubbleColor: '#056869',
    botFontColor: '#fff',
    userBubbleColor: '#fff',
    userFontColor: '#4a4a4a',
  };


  const steps = [
    {
      id: '0',
      message: 'Como posso te ajudar?',
      trigger: '4',
    },
    {
      id: '4',
      options: [
        { value: 1, label: 'Inserir Produto', trigger: 'P01:1' },
        { value: 2, label: 'CPF Inválido', trigger: 'P02' },
        { value: 3, label: 'Alterar Pedido', trigger: 'P03' },
        { value: 4, label: 'Problema com Maquininha', trigger: 'P04' },
        { value: 5, label: 'Duvidas com Faturamento', trigger: 'P05' },
        { value: 6, label: 'Preço divergente', trigger: 'P03:2' },
        // { value: 7, label: 'Cadastrar usuário', trigger: 'P07' },
        // { value: 8, label: 'Alterar Usuário', trigger: 'P08' },
        { value: 9, label: 'Loja que está o produto não atende', trigger: 'P09' },
        // { value: 10, label: 'Outros', trigger: 'P10' },


      ],
    },
    {
      id: 'P01:1',
      message: 'Qual o reduzido?',
      trigger: 'digita_reduzido',
    },
    {
      id: 'digita_reduzido',
      user: true,
      inputAttributes: {
        keyboardType: 'default'
      },
      trigger: 'produto_inserido_com_sucesso',
    },

    {
      id: 'produto_inserido_com_sucesso',
      asMessage: true,
      component: <AdicionarProdutoBySync reduzido={'020664'} />,
      trigger: 'loading_inserir_produto',
    },
    {
      id: 'loading_inserir_produto',
      delay: 40000,
      message: "Clique no botão abaixo para voltar no início",
      trigger: 'menu_inicial_inserir_produto',
    },


    /////// P01 ENCERRADO
    {
      id: 'P02',
      message: 'O Sistema tem uma validação de CPF para que possa ser feita a venda. Se aparecer a mensagem de CPF INVÁLIDO, esse cpf nao existe',
      trigger: 'final',
    },
    /////// P02 ENCERRADO

    {
      id: 'P03',
      options: [
        { value: 1, label: 'Alterar Produto', trigger: 'P03:1' },
        { value: 2, label: 'Alterar Preço de produto', trigger: 'P03:2' },
        { value: 3, label: 'Alterar cor do pedido', trigger: 'P03:3' },
        { value: 4, label: 'Alterar numeração', trigger: 'P03:3' }
      ],
    },
    {
      id: 'P03:1',
      message: 'O painel de gerencimento de pedidos ainda está em desenvolvimento',
      trigger: 'P03:1:1',
    },
    {
      id: 'P03:1:1',
      message: 'Refaça o pedido novamente',
      trigger: 'final',
    },
    {
      id: 'P03:2',
      message: 'O preço no Venda Fácil está mais caro que na sua loja?',
      trigger: 'P03:2:1',
    },
    {
      id: 'P03:2:1',
      options: [
        { value: 1, label: 'Sim', trigger: 'produto_saldo_pergunta' },
        { value: 2, label: 'Não', trigger: 'P03:2:1:2' },
      ],
    },
    {
      id: 'produto_saldo_pergunta',
      message: 'O produto que está realizando a venda é SALDO?',
      trigger: 'produto_saldo_resposta',
    },
    {
      id: 'produto_saldo_resposta',
      options: [
        { value: 1, label: 'Sim', trigger: 'P03:2:1:2' },
        { value: 2, label: 'Não', trigger: 'P03:2:1:1:1' },
      ],
    },

    {
      id: 'P03:2:1:1',
      message: 'O painel de gerencimento de pedidos] ainda está em desenvolvimento',
      trigger: 'P03:2:1:1:1',
    },
    {
      id: 'P03:2:1:1:1',
      message: 'Entre em contato com o setor para alteração do preço e prosseguimento da venda',
      trigger: 'final',
    },
    {
      id: 'P03:2:1:2',
      message: 'Conforme alianhado com a diretoria, foi definido que manterá o preço na filial que irá faturar',
      trigger: 'final',
    },

    {
      id: 'P03:3',
      message: 'O painel de gerencimento de pedidos ainda está em desenvolvimento',
      trigger: 'P03:3:1',
    },
    {
      id: 'P03:3:1',
      message: 'Refaça o pedido novamente',
      trigger: 'final',
    },
    /////// P03 ENCERRADO

    {
      id: 'P04',
      message: 'Qual mensagem aparece na tela do COMPUTADOR da pagina pagamento.katuxa.com.br ?',
      trigger: 'P04:1',
    },
    {
      id: 'P04:1',
      options: [
        { value: 1, label: 'Servidor Desconectado', trigger: 'P04:1:1' },
        { value: 2, label: 'Processando Pagamento', trigger: 'P04:1:2' },
        { value: 3, label: 'Não foi possível conectar ao Zoop', trigger: 'P04:1:3' },
      ],
    },
    {
      id: 'P04:1:1',
      asMessage: true,
      component: <ReactPlayer width={300} controls={true} url='https://player.vimeo.com/video/701373701?h=9a836f0f62' />,
      trigger: 'final',
    },
    {
      id: 'P04:1:2',
      message: 'Esse é um problema no ZOOP PC, deve ter dois programas abertos. Abra o gerenciamento de tarefas do Windows, fecha os Programas do Zoop PC em aberto e incie o Zoop PC novamente.',
      trigger: 'final',
    },
    {
      id: 'P04:1:3',
      message: 'Entre em contato com o setor, possívelmente esse é um problema de bloqueio da Zoop com a rede da Katuxa',
      trigger: 'final',
    },

    //// P05

    {
      id: 'P05',
      message: 'Veja o video abaixo do processo de faturamento de nota fiscal do Venda Fácil',
      trigger: 'P05:1',
    },
    {
      id: 'P05:1',
      asMessage: true,
      component: <ReactPlayer width={300} controls={true} url='https://player.vimeo.com/video/702971401?h=6add20be08' />,
      trigger: 'final',
    },
    {
      id: 'final',
      message: 'Te ajudo em algo mais?',
      trigger: 'final:1'
    },
    {
      id: 'final:1',
      options: [
        { value: 1, label: 'Sim', trigger: '0' },
        { value: 2, label: 'Não', trigger: 'final:2' },
      ],
    },
    {
      id: 'final:2',
      message: 'Se precisar é chamar!',
      trigger: 'final:3'
    },

    {
      id: 'final:3',
      options: [
        { value: 1, label: 'Menu', trigger: '0' },
      ],
    },

    {
      id: 'P09',
      message: 'Vamos pedir para que entre em contato com o supervisor responsável Osmar e relate o ocorrido para que possa tomar',
      trigger: 'P09:1',
    },
    {
      id: 'P09:1',
      message: 'Numero: 37 9139-2211 e relate o ocorrido para que possa tomar as devidas providências',
      trigger: 'final',
    },

    //final 2

    {
      id: 'menu_inicial_inserir_produto',
      delay: 50000,
      options: [
        { value: 1, label: 'Voltar ao MENU INCIAL', trigger: '0' },
      ],
    },

  ];

  useEffect(() => {

    getListaPedidosByStatus2({ status1: "60774fb684e6861cb41a4730", status2: "60774fa684e6861cb41a472f", isHold: false, filialUsuario: getFilialUser() }).then(res => {
      if (res.data.length > 0) {
        notifyMe('Existem pedidos para separar');
      }

    });



    setInterval(() => {
      getListaPedidosByStatus2({ status1: "60774fb684e6861cb41a4730", status2: "60774fa684e6861cb41a472f", isHold: false, filialUsuario: getFilialUser() }).then(res => {
        if (res.data.length > 0) {
          notifyMe('Existem pedidos para separar');
        }
      });
    }, 180000);


  }, [])





  const handleKeyUp = e => {
    if (e.key === 'Enter') {
      handlePesquisarProdutos();
    }
  };

  //menus categorias

  const handleChange = (event, newValue) => {
    irCatMneuMobile(newValue)
  };

  const handleOpenMenuEspeciais = (event) => {
    setAnchorElEspeciais(event.currentTarget);
  };


  const handleOpenMenuLojas = (event) => {
    setAnchorElLojas(event.currentTarget);
  };

  const escolhaProsseguirVenda = () => {
    history.push('/pdv/resumo');
    dispatch({ type: Actions.DRAWER_CARRINHO, drawerCarrinho: false });
    setOpen(false);
  };

  const escolhaContinuarComprando = () => {
    setOpen(false);
    dispatch({ type: Actions.DRAWER_CARRINHO, drawerCarrinho: false });

  };

  const irCatMneuMobile = value => {
    dispatch({ type: Actions.LOADING_PAGE, loadingPage: true });
    dispatch({ type: Actions.SET_TODOS_PRODUTOS, products: [] });
    listaProdutosPorLinha({ linha: value }).then(res => {
      if (res.data.produtos.length > 0) {
        dispatch({ type: Actions.SET_TODOS_PRODUTOS, products: res.data.produtos });

        history.push(`/pdv/c/${value}`);
        dispatch({ type: Actions.LOADING_PAGE, loadingPage: false })
      }

    }).catch(err => console.log(err));
  }

  const irExclusivos = value => {
    dispatch({ type: Actions.LOADING_PAGE, loadingPage: true });
    dispatch({ type: Actions.SET_TODOS_PRODUTOS, products: [] });
    listaProdutosExclusivos({ atributo: value }).then(res => {
      if (res.data.produtos.length > 0) {
        dispatch({ type: Actions.SET_TODOS_PRODUTOS, products: res.data.produtos });

        history.push(`/pdv/exclusivo/${value}`);
        dispatch({ type: Actions.LOADING_PAGE, loadingPage: false })
      }

    }).catch(err => console.log(err));
  }


  const irCat = data => {
    dispatch({ type: Actions.LOADING_PAGE, loadingPage: true });
    dispatch({ type: Actions.SET_TODOS_PRODUTOS, products: [] });
    listaProdutosPorLinha({ linha: data.slug }).then(res => {
      if (res.data.produtos.length > 0) {
        dispatch({ type: Actions.SET_TODOS_PRODUTOS, products: res.data.produtos });

        history.push(`/pdv/c/${data.slug}`);
        dispatch({ type: Actions.LOADING_PAGE, loadingPage: false })
      }

    }).catch(err => console.log(err));
  }

  const handlePesquisarProdutos = async () => {
    dispatch({ type: Actions.LOADING_PAGE, loadingPage: true });
    history.push('/pdv/busca');
    let querys = `?search=${search}`;
    const response = await v2_actionPesquisarProdutos(querys);
    if(response.status === 200){
      dispatch({ type: Actions.SET_TODOS_PRODUTOS, products: response.data.produtos });
      dispatch({ type: Actions.SEARCH, search: search });
      dispatch({ type: Actions.LOADING_PAGE, loadingPage: false })
    }

  }

  const irFrete = () => {
    // setOpen(true);
    escolhaProsseguirVenda()
  };

  const logout = () => {
    localStorage.removeItem('jwt');
    history.push('/login');
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => history.push('/pdv/pedidos')}>Painel de Pedidos</MenuItem>
      <MenuItem onClick={() => window.open('https://pagamento.katuxa.com.br', '_blank')}>Terminal de Pagamentos</MenuItem>
      <MenuItem onClick={() => history.push('/pdv/vendas')}>Métricas</MenuItem>
      <MenuItem onClick={logout}>Sair</MenuItem>
      {/* <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
    </Menu>
  );

  const renderHeader = (
    <>
      <Grid style={{ display: 'flex', alignItems: 'center' }} spacing={3} container>
        <Grid item md={3}>
          <Stack direction="row" spacing={1}>

            <Button
              variant='none'
              size="small"
              sx={{ color: '#333' }}
              onClick={() => history.push('/pdv')}
              startIcon={<Iconify icon="ion:home" />}
            >
              Incício
            </Button>

            {/* <Button
              variant='none'
              size="small"
              sx={{ color: '#333' }}
              onClick={() => setOpenDrawer(true)}
              startIcon={<Iconify icon="ion:list" />}
            >
              Categorias
            </Button> */}
          </Stack>
        </Grid>
        <Grid item md={6}>
          <OutlinedInput
            id="outlined-basic"
            fullWidth
            style={{ height: 45, border: '1px solid #333' }}
            placeholder='Buscar por reduzido ou nome'
            variant="filled"
            type="search"

            onKeyUp={e => handleKeyUp(e)}
            onChange={e => setSearch(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={handlePesquisarProdutos}>
                  <FaSearch />
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item md={3}>
          <Stack >

            <Stack justifyContent={"end"} direction="row" spacing={1}>

              <IconButton aria-label="show 17 new notifications" color="#333" onClick={() => { dispatch({ type: Actions.DRAWER_CARRINHO, drawerCarrinho: true }) }}>
                <Badge badgeContent={cartItems.length} color="secondary">
                  <Iconify icon="mdi:cart-outline" />
                </Badge>
              </IconButton>
              <Button
                edge="end"
                aria-controls={menuId}
                aria-haspopup="true"
                variant='outlined'
                color="inherit"
                endIcon={<Iconify color="#333" icon="ion:person" />}
                onClick={handleProfileMenuOpen}
              >
                <Typography color="#333"> {localStorage.getItem("Usuario")}</Typography>
              </Button>


            </Stack>




          </Stack>
        </Grid>
      </Grid>


      <DrawerMenuCategorias
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      />


      {/* <Grid container>
        <Grid item md={11}>
          <Box style={{ display: 'flex' }}>
            {MENU.map(data => (

              <Grid item xs={6} md xl sm={2} >
                <CardMenuCat
                  key={data.slug}
                  titulo={data.titulo}
                  path={() => irCat(data)}
                />
              </Grid>
            ))}

          </Box>
        </Grid>

        <Grid item md={1}>
          <Box style={{ display: 'flex', margin: 5 }}>
            <Button onClick={handleOpenMenuEspeciais} aria-controls="especiais-menu-principal" endIcon={<BsChevronDoubleDown size={15} />} style={{ margin: 5 }} >Exclusivos</Button>
            <Menu
              id="especiais-menu-principal"
              anchorEl={anchorElEspeciais}
              keepMounted
              open={Boolean(anchorElEspeciais)}
              onClose={() => setAnchorElEspeciais(null)}
            >
              <MenuItem onClick={() => irExclusivos('chuteiras')} >Chuteiras</MenuItem>
              <MenuItem onClick={() => irExclusivos('tamanhos-especiais')} >Tam. Especiais</MenuItem>
            </Menu>
          </Box>
        </Grid>
      </Grid> */}

    </>

  );



  const renderHeaderMobile = (
    <Container style={{ margin: 0 }}>
      <Grid style={{ display: 'flex', alignItems: 'center', marginTop: 5, marginBottom: 5 }} spacing={1} container>
        <Grid item xs={6} md={3}>
          <Box style={{ display: 'flex', alignItems: 'center' }} >
            <IconButton
              edge="start"

              color="inherit"
              aria-label="open drawer"
            >
              <img onClick={() => { history.push('/pdv') }} width={100} src={Logo} />
            </IconButton>
          </Box>
        </Grid>

        <Grid item xs={6} md={3}>
          <Box style={{ display: 'flex', alignItens: 'flex-end' }}>

            <div />
            <div >
              <IconButton aria-label="show 17 new notifications" color="#333" onClick={() => { dispatch({ type: Actions.DRAWER_CARRINHO, drawerCarrinho: true }) }}>
                <Badge badgeContent={cartItems.length} color="secondary">
                  <BsCart3 />
                </Badge>
              </IconButton>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="#333"
              >
                <Typography> {localStorage.getItem("Usuario")}</Typography>
              </IconButton>
            </div>


            {/* 
              <IconButton aria-label="show 17 new notifications" color="#333" onClick={() => { dispatch({ type: Actions.DRAWER_CARRINHO, drawerCarrinho: true }) }}>
                <Badge badgeContent={cartItems.length} color="secondary">
                  <HiShoppingCart size={30} />
                </Badge>
              </IconButton> */}
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="#333"
            >
              <BsGrid />
            </IconButton>

          </Box>
        </Grid>

        <Grid item xs={10} md={6}>
          <OutlinedInput
            id="outlined-basic"
            fullWidth
            size="small"
            style={{ height: 45, border: '1px solid #000' }}
            placeholder='Buscar por reduzido ou nome'
            type="search"
            variant="outlined"
            onKeyUp={e => handleKeyUp(e)}
            onChange={e => setSearch(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={handlePesquisarProdutos}>
                  <FaSearch />
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>

        <Grid item xs={2} md={2}>
          <Box style={{ display: 'flex' }}>
            <IconButton onClick={handleOpenMenuEspeciais} aria-controls="especiais-menu-principal"  > <FaStar size={25}>Exclusivos</FaStar> </IconButton>
            <Menu
              id="especiais-menu-principal"
              anchorEl={anchorElEspeciais}
              keepMounted
              open={Boolean(anchorElEspeciais)}
              onClose={() => setAnchorElEspeciais(null)}
            >
              <MenuItem onClick={() => irExclusivos('chuteiras')} >Chuteiras</MenuItem>
              <MenuItem onClick={() => irExclusivos('tamanhos-especiais')} >Tam. Especiais</MenuItem>
            </Menu>
            {/* <Button onClick={handleOpenMenuLojas} aria-controls="lojas-menu-principal"  endIcon={<BsChevronDoubleDown size={15} />} style={{margin:5}} >Lojas</Button>
          <Menu
            id="lojas-menu-principal"
            anchorEl={anchorElLojas}
            keepMounted
            open={Boolean(anchorElLojas)}
            onClose={() => setAnchorElLojas(null)}
          >
            <MenuItem>New Tennis</MenuItem>
          </Menu> */}
          </Box>
        </Grid>

      </Grid>




      <Grid container>


        <AppBar position="static" color='default' elevation={0}>
          <Tabs
            variant="scrollable"
            onChange={handleChange}
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
          >
            <Tab value="feminino" label="Feminino" />
            <Tab value="masculino" label="Masculino" />
            <Tab value="infantil" label="Infantil" />
            <Tab value="esportivo" label="Esportivo" />
            <Tab value="acessorios" label="Acessórios" />
          </Tabs>
        </AppBar>
      </Grid>
    </Container>
  )

  return (
    <>
      <CssBaseline />
      <Dialog
        open={open}
        maxWidth={'md'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Deseja mesmo prosseguir com a venda?</DialogTitle>

        <DialogActions>
          <Button onClick={escolhaContinuarComprando} color="primary">
            Adicionar Mais Produtos
          </Button>
          <Button onClick={escolhaProsseguirVenda} style={{ background: '#229743', color: '#fff' }} variant="contained" autoFocus>
            CONTINUAR
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogSuporte}
        maxWidth={'md'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Chat Venda Fácil</DialogTitle>
        <DialogContent>


          <ThemeProvider theme={theme}>
            <ChatBot width={'45vw'} botDelay={1000} headerTitle={"Fale com a Kátia, a IA da Katuxa"} placeholder={"Digite aqui..."} hideHeader={true} steps={steps} />
          </ThemeProvider>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => { setOpenDialogSuporte(false) }} style={{ background: '#229743', color: '#fff' }} variant="contained" autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <IconButton onClick={() => setOpenDialogSuporte(true)} style={{ position: 'fixed', background: '#056869', bottom: 5, right: 5 }}>
        <BiHelpCircle color={'#fff'} size={30} />
      </IconButton>


      <AppBar position='static' elevation={0} sx={{ background: '#f9f9f9', height:70, borderBottom: '1px solid #ccc' }}>
        <Toolbar>
          {isDesktop ? renderHeader : renderHeaderMobile}
        </Toolbar>
      </AppBar>

      {renderMenu}


      <Drawer sx={{width:400}} anchor={'right'} open={drawerCarrinho} onClose={() => dispatch({ type: Actions.DRAWER_CARRINHO, drawerCarrinho: false })}>
        <Cart dadosFrete={"teste3"} IrFrete={irFrete} />
      </Drawer>

      {loadingPage ? (
        <Container style={{ display: 'flex', alignItems: 'center', height: 'calc(100vh - 200px)' }} maxWidth={false}>
          <Grid container style={{ justifyContent: 'center' }}>
            <Grid item>
              <LoadingModal />
            </Grid>
          </Grid>
        </Container>

      ) : (

        <Container style={{ margin: 0, padding: 0 }} maxWidth={false}>
          <Route exact path='/'>
            {!token ? <Redirect to="/login" /> : (<Introduction />)}
          </Route>

          <Route exact path='/pdv'>
            {!token ? <Redirect to="/login" /> : (<Introduction />)}
          </Route>

          <Route path='/pdv/c/:linha'>
            {!token ? <Redirect to="/login" /> : (<Linha />)}
          </Route>

          <Route path='/pdv/exclusivo/:exclusivo'>
            {!token ? <Redirect to="/login" /> : (<Exclusivos />)}
          </Route>

          <Route path='/pdv/lojas/:codigo_filial'>
            {!token ? <Redirect to="/login" /> : (<Lojas />)}
          </Route>

          <Route path='/pdv/busca'>
            {!token ? <Redirect to="/login" /> : (<Order search={search} />)}
          </Route>

          <Route path='/pdv/frete'>
            {!token ? <Redirect to="/login" /> : (<Shipping />)}
          </Route>

          <Route path='/pdv/cadastro'>
            {!token ? <Redirect to="/login" /> : (<Cadastro />)}
          </Route>

          <Route path='/pdv/pedidos'>
            {!token ? <Redirect to="/login" /> : (<Pedidos />)}
          </Route>
          <Route path='/pdv/pagamento'>
            {!token ? <Redirect to="/login" /> : (<Pagamento />)}
          </Route>
          <Route path='/pdv/resumo'>
            {!token ? <Redirect to="/login" /> : (<Resumo />)}
          </Route>
          <Route path='/pdv/conclusao'>
            {!token ? <Redirect to="/login" /> : (<Conclusao />)}
          </Route>

          <Route path='/pdv/vendas'>
            {!token ? <Redirect to="/login" /> : (<Vendas />)}
          </Route>

          <Route path='/pdv/suporte'>
            {!token ? <Redirect to="/login" /> : (<Suporte />)}
          </Route>
        </Container>
      )}




    </>
  );
}

export default App;
