import React, { useEffect, useState } from 'react'

import { useHistory } from "react-router-dom";
import { Card, Typography, Stack, Box, Container, IconButton, Button, Radio, Grid, TextField } from '@mui/material';

import { format } from 'date-fns';
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../constants/Actions";
import { BiStore } from 'react-icons/bi';
import { FaShippingFast } from 'react-icons/fa';


import { getCepUser, dataUser, getDataUsuario } from "../../services/auth";
// actions
import buscaCep from "../../actions/buscaCep";
import cotarCorreios from '../../actions/global/cotarCorreios'

//components
import Skeleton from '@material-ui/lab/Skeleton';
import { getValorSubTotal, pesoCalculado, maiorLarguraItems, maiorComprimentoItems, alturaCalculado } from '../../utils/functions';

// actions
import buscarFilialPorCodigoFilial from '../../actions/cadastro/buscar-filial-por-codigo-filial';
import actionConfigVendaFacil from '../../actions/config/actionConfigVendaFacil';
import actionBuscarFiliaisCidades from '../../actions/config/actionBuscarFiliaisCidades';


const CheckoutShipping = ({ proximoPasso }) => {

    const [tipoEntrega, setTipoEntrega] = useState('Retirada');
    const [transportadora, setTransportadora] = useState(null);
    const [cepProduto, setCepProduto] = useState('');
    const [fretePatrus, setFretePatrus] = useState('19.90');
    const [freteCorreios, setFreteCorreios] = useState("");
    const [freteCorreiosSedex, setFreteCorreiosSedex] = useState("");
    const [mostrarFretesEntregas, setMostrarFretesEntregas] = useState(false);
    const [isLoadingFrete, setIsLoadingFrete] = useState(false);
    const [cepDigitado, setCepDigitado] = useState('');
    const [diasEntrega, setDiasEntrega] = useState('');
    const [valorFreteRetirada, setValorFreteRetirada] = useState(0);
    const [config, setConfig] = useState(null);
    const [entregaBloqueada, setEntregaBloqueada] = useState(false);
    const [retiradaGratis, setRetiradaGratis] = useState(false);
    // cep
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');

    const cepOrigemProduto = useSelector(state => state.cepOrigemProduto);
    const cartItems = useSelector(state => state.cartItems);
    const valorFrete = useSelector(state => state.valorFrete);
    const filialUsuario = getDataUsuario().filial

    const [subtotal, setSubtotal] = React.useState(0)
    const date = new Date();

    const dispatch = useDispatch();
    const history = useHistory();

    async function buscarConfig() {
        try {
            const res = await actionConfigVendaFacil();
            setConfig(res.data.data.vendafacil);
            return res.data.data.vendafacil;

        } catch (err) {
            console.log('Erro ao buscar config', err)
        }
    }


    async function validaEntregaFranquia() {
        const res_filiais_cidades = await actionBuscarFiliaisCidades(filialUsuario);
        if (!res_filiais_cidades.data.data) return;
        const res_config = await buscarConfig();
        const filiaisCidades = res_filiais_cidades.data.data.filiais;
        let isFilialImpedida = res_config.filais_impedidas_entrega.includes(filialUsuario) // verifica sa loja do usuario esta impedida de fazer envios na cidade

        if (!isFilialImpedida) return;

        if (!cartItems) return;
        if (cartItems.length === 0) return;
        if (!res_config) return;
        let filiaisCarrinho = cartItems.map(i => i.filial);
        if (filiaisCidades.some(valor => filiaisCarrinho.includes(valor))) {
            console.log('filiaisCarrinho.includes(filiaisCidades)', filiaisCarrinho.includes(filiaisCidades))
            setEntregaBloqueada(true);
            setRetiradaGratis(true)
            setValorFreteRetirada(0)
        } else {
            console.log('erro', filiaisCarrinho, filiaisCidades)
        }
    }




    useEffect(() => {
        if (cartItems.length == 0) {
            alert('Seu Carrinho está vazio, você será redirecionado para a página inicial')
            history.push('/');
        }
        validaEntregaFranquia()
        buscarFilialPorCodigoFilial(cepOrigemProduto).then(res => {
            setCepProduto(res.data.cep)
        })

    }, []);


    const handleEntrega = () => {
        dispatch({ type: Actions.SHIPPING_ID, shippingId: null });

        setTipoEntrega('Entrega');
        dispatch({ type: Actions.TIPO_ENTREGA, tipoEntrega: "Entrega" });
        setTransportadora('')
    }



    const handleBuscaCep = async (zipCode) => {
        try {
            const address = await buscaCep(zipCode)
            if (address.erro) {
                alert('CEP ano encontrado!')
            } else {
                setCidade(address.localidade);
                setEstado(address.uf);
                return address;
            }
        } catch (address) {
            alert('CEP ano encontrado!');
        }
    }



    const handleBuscarFreteEntrega = async (e) => {
        e.preventDefault();
        setIsLoadingFrete(true);
        setMostrarFretesEntregas(true)
        const response = await handleBuscaCep(cepDigitado);

        cotarCorreios({
            altura: alturaCalculado(cartItems),
            comprimento: maiorComprimentoItems(cartItems),
            largura: maiorLarguraItems(cartItems),
            peso: pesoCalculado(cartItems),
            cepOrigem: cepProduto,
            cepDestino: cepDigitado
        }
        ).then(res => {
            const freteInt = parseInt(res.data.result.sedex.Valor);
            if (getValorSubTotal(cartItems) > 199) {
                if (response.uf === 'MG') {
                    setFreteCorreios(0);
                    setFreteCorreiosSedex(0);
                    setIsLoadingFrete(false);
                } else {
                    if (res.data.result.sedex.Valor < 1) {
                        setFreteCorreios(20);
                        setFreteCorreiosSedex(20);
                        setIsLoadingFrete(false);
                    } else {
                        setFreteCorreios(res.data.result.sedex.Valor.replace(',', '.'));
                        setFreteCorreiosSedex(res.data.result.pac.Valor.replace(',', '.'));
                        setIsLoadingFrete(false);
                    }
                }
            } else {

                if (freteInt === 0) {
                    setFreteCorreios(20);
                    setFreteCorreiosSedex(20);
                    setIsLoadingFrete(false);
                } else {

                    setFreteCorreios(res.data.result.sedex.Valor.replace(',', '.'));
                    setFreteCorreiosSedex(res.data.result.pac.Valor.replace(',', '.'));
                    setIsLoadingFrete(false);
                }

            }
        }).catch(e => {
            setFreteCorreios(20);
            setFreteCorreiosSedex(20);
            setIsLoadingFrete(false);
            alert('Erro ao calcular o frete, entre em contato com o suporte para averiguar o erro ou tente novamente.')
        })

    }

    const handleRetirada = () => {
        dispatch({ type: Actions.SHIPPING_ID, shippingId: null });

        if (retiradaGratis) {
            setValorFreteRetirada(0);
            dispatch({ type: Actions.TIPO_ENTREGA, tipoEntrega: "Retirada" });
            setTipoEntrega('Retirada');
            setTransportadora('')
            return;
        }
        if (getCepUser() >= 35500000 & getCepUser() <= 35505000) {
            setValorFreteRetirada(0)
        } else {
            if (getValorSubTotal(cartItems) < 149) {
                setValorFreteRetirada(20)
            } else {
                setValorFreteRetirada(0)
            }
        }
        dispatch({ type: Actions.TIPO_ENTREGA, tipoEntrega: "Retirada" });
        setTipoEntrega('Retirada');
        setTransportadora('')
    }



    const handleEscolhePac = () => {
        setTransportadora('Pac');
        dispatch({ type: Actions.valorFrete, valorFrete: Number.parseFloat(freteCorreios) });
        dispatch({ type: Actions.SHIPPING_ID, shippingId: "61532d990d253dd8a450bcde" });


    }


    const handleEscolhePatrus = () => {
        setTransportadora('Patrus');
        dispatch({ type: Actions.SHIPPING_ID, shippingId: "61560313eaf72f4fe7f5ee25" });
        dispatch({ type: Actions.valorFrete, valorFrete: fretePatrus.ValorFrete });
    }


    const handleEscolhePropria = () => {

        if (retiradaGratis) {
            setValorFreteRetirada(0);
            dispatch({ type: Actions.TIPO_ENTREGA, tipoEntrega: "Retirada" });
            setTipoEntrega('Retirada');
            setTransportadora('Total');
            dispatch({ type: Actions.valorFrete, valorFrete: 0.00 })
            dispatch({ type: Actions.SHIPPING_ID, shippingId: "61560325eaf72f4fe7f5ee27" });
            return;
        }
        setTransportadora('Total');
        dispatch({ type: Actions.SHIPPING_ID, shippingId: "61560325eaf72f4fe7f5ee27" });
        dispatch({ type: Actions.valorFrete, valorFrete: 0.00 })

        if (tipoEntrega === "Retirada") {
            if (getCepUser() >= 35500000 & getCepUser() <= 35505000) {
                dispatch({ type: Actions.valorFrete, valorFrete: 0.00 })
            } else {
                if (getValorSubTotal(cartItems) < 149) {
                    dispatch({ type: Actions.valorFrete, valorFrete: 20.00 })
                } else {
                    dispatch({ type: Actions.valorFrete, valorFrete: 0.00 })
                }
            }
        }


        if (tipoEntrega === 'Entrega') {
            if (cepDigitado >= 35500000 & cepDigitado <= 35505000) {
                if (getValorSubTotal(cartItems) < 199) {
                    dispatch({ type: Actions.valorFrete, valorFrete: 14.00 })
                } else {
                    dispatch({ type: Actions.valorFrete, valorFrete: 0.00 })
                }

            } else {
                if (getValorSubTotal(cartItems) < 199) {
                    dispatch({ type: Actions.valorFrete, valorFrete: 20.00 })
                } else {
                    dispatch({ type: Actions.valorFrete, valorFrete: 0.00 })
                }
            }

        }

    }

    const validaEntregaPropria = () => {
        if (cepDigitado >= 35500000 & cepDigitado <= 35505000) {
            if (getValorSubTotal(cartItems) < 199) {
                return 14.00;
            } else {
                return 0.00
            }

        } else {
            if (getValorSubTotal(cartItems) < 199) {
                return 18.00;
            } else {
                return 0.00
            }
        }
    }


    const voltarPdv = () => {
        history.push('pdv');
        dispatch({ type: Actions.valorFrete, valorFrete: 0 });
    }

    const CardTransportadora = ({ click, img, tipoEntrega, titulo, valorFrete, dataMaxima, valorItens }) => {

        function valorRestante() {
            if (valorItens < 149) {
                let total = 150 - valorItens;
                return total.toFixed(2);
            }
        }
        return (
            <Card sx={{
                borderRadius: 0.5,
                p: 2,
                boxShadow: 1
            }}
            >
                <Stack direction={"row"} spacing={1}>
                    <Radio
                        checked={transportadora === 'Total'}
                        value={transportadora}
                        name="radio-button-demo"

                    />
                    <Stack>
                        <Typography variant="h6">{titulo} - R${valorFrete}</Typography>
                        <Typography sx={{ fontSize: 14 }}> {valorFreteRetirada > 0 && tipoEntrega === 'retirada' && <Typography variant='caption'>Faltam <strong>R${valorRestante(valorItens)}</strong> para o frete ser grátis</Typography>}</Typography>
                    </Stack>
                </Stack>
            </Card>
        )
    }

    const CardTransportadoraEntrega = ({ click, img, tipoEntrega, titulo, valorFrete, dataMaxima }) => {
        return (
            <Card sx={{
                borderRadius: 1,
                p: 2,
                boxShadow: 1,
                background: transportadora == 'Total' ? '#ccc' : 'transparent'
            }}
            >
                <Stack>
                    <Typography sx={{fontSize:14}}>{titulo} - R$ {valorFrete}</Typography>
                    <Typography variant="caption"> {titulo === 'Total Express' ? (
                        <Typography variant="caption">Chega até <strong>2 dias úteis após o pagamento</strong></Typography>
                    ) : (
                        <Typography variant="caption">Chega até <strong>{dataMaxima ? format(new Date(dataMaxima), 'dd/MM') : dataMaxima}</strong></Typography>
                    )}</Typography>
                </Stack>
            </Card>
        )
    }

    const ItemShippingMethod = ({ background, title, onClick }) => {
        return (
            <Card
                onClick={onClick}
                sx={{
                    cursor: 'pointer',
                    width: '100%',
                    borderRadius: 1,
                    p: 2,
                    boxShadow: 1,
                    background: background
                }}
            >
                <Typography>{title}</Typography>
            </Card>
        )
    }


    return (
        <>
            <Stack sx={{ mt: 2 }} direction="row" spacing={3}>
                <ItemShippingMethod
                    background={tipoEntrega == 'Retirada' ? '#b6ffca' : ''}
                    title="Retirada"
                    onClick={handleRetirada}
                />
                {!entregaBloqueada && (
                    <ItemShippingMethod
                        background={tipoEntrega == 'Entrega' ? '#b6ffca' : ''}
                        title="Entrega"
                        onClick={handleEntrega}
                    />
                )}

            </Stack>
            <Box sx={{ p: 2, mt:3 }}>
                <Stack direction={"column"} spacing={2}>
                    {tipoEntrega === 'Entrega' && (
                        <Stack>
                            <Stack spacing={1}>
                                <Stack>
                                    <Typography variant="subtitle1">Calcular Frete para Entrega</Typography>
                                </Stack>
                                <Stack spacing={1} direction={"row"}>
                                    <TextField
                                        type="text"
                                        value={cepDigitado}
                                        id="zip"
                                        onChange={e => setCepDigitado(e.target.value)}
                                        required
                                    />
                                    <Button
                                    variant='contained'
                                    color='info'
                                        onClick={handleBuscarFreteEntrega}
                                    >
                                        Calcular Frete
                                    </Button>
                                </Stack>
                            </Stack>

                            <Stack sx={{mt:1}}>
                                {mostrarFretesEntregas && (
                                    <>
                                        {cidade !== "Divinópolis" && (
                                            <>
                                                {isLoadingFrete === false ? (
                                                    <Grid item xl={3} md={4} lg={3}>
                                                        <div style={{ background: transportadora == 'Pac' ? '#ccc' : '' }} onClick={handleEscolhePac} className='card'>
                                                            <CardTransportadoraEntrega
                                                                click={handleEscolhePac}
                                                                img={'https://katuxa.sfo3.digitaloceanspaces.com/logos/correios.png'}
                                                                tipoEntrega={'correios'}
                                                                valorFrete={freteCorreios}
                                                                dataMaxima={(new Date()).setDate(date.getDate() + 8)}
                                                                titulo={"PAC"}
                                                            />
                                                        </div>
                                                    </Grid>
                                                ) : (
                                                    <Grid item xl={3} md={4} lg={3}>
                                                        <div style={{ background: '#fff' }} className='card card-body'>
                                                            <Skeleton variant="circle" width={40} height={40} />
                                                            <Skeleton variant="text" width={200} height={100} />
                                                            <Skeleton variant="text" width={200} height={30} />
                                                            <Skeleton variant="text" width={200} height={30} />
                                                        </div>
                                                    </Grid>
                                                )}
                                            </>
                                        )}

                                        {cidade === "Divinópolis" && (
                                            <>
                                                <div style={{ marginTop:25, background: transportadora == 'Total' ? '#ccc' : '' }} onClick={handleEscolhePropria} className='card'>
                                                    <CardTransportadoraEntrega
                                                        click={() => handleEscolhePatrus()}
                                                        img={'https://katuxa.sfo3.digitaloceanspaces.com/logos/katuxa.png'}
                                                        tipoEntrega={'katuxa'}
                                                        valorFrete={validaEntregaPropria()}
                                                        dataMaxima={date.setDate(date.getDate() + 2)}
                                                        titulo={"Entrega Própria"}
                                                    />
                                                </div>

                                            </>
                                        )}
                                    </>
                                )}
                            </Stack>

                        </Stack>
                    )}

                    {tipoEntrega === 'Retirada' && (

                        <Card
                            onClick={handleEscolhePropria}
                            sx={{
                                background: transportadora == 'Total' ? '#ccc' : '',
                                border: 1,
                                borderColor: "#f0f0f0",
                                borderWidth: 1,
                                borderStyle: 'solid'
                            }}
                        >
                            <CardTransportadora
                                click={() => handleEscolhePropria()}
                                img={'https://katuxa.sfo3.digitaloceanspaces.com/logos/katuxa.png'}
                                tipoEntrega={'retirada'}
                                valorFrete={valorFreteRetirada}
                                dataMaxima={(new Date()).setDate(date.getDate() + 8)}
                                titulo={"Entrega Katuxa"}
                                valorItens={getValorSubTotal(cartItems)}
                            />
                        </Card>
                    )}

                </Stack>


            </Box>
        </>

    )
}

export default CheckoutShipping;
