

export default {
    dev:false,
    api_dev:'http://localhost:3001',
    api_prod:'https://api.katuxa.com.br',
    api_crmbonus_homo:'https://homol.crmbonus.com/pages',
    authorization_crmbonus:'Q1JNJkJvbnVzQVBJIzIwMTg=',
    codempresa_crmbonus:'TzV1cFRTbXRNeVNVbTg1UA==',
    api: 'https://api.katuxa.tk/api/',
    tokenYapay:'90cb732880e3c96',
    createCartYapay:'https://api.intermediador.sandbox.yapay.com.br/v1/tmp_transactions/create',
    base_fotos:'https://api.katuxa.com.br/files/vendafacil',
    baseUrl: 'http://fotos.katuxa.com.br',
    baseApi: 'http://fotos.katuxa.com.br',
    tokenPatrus: `2`,
    baseZoop: 'https://api.zoop.ws',
    zpk_sandbox:"zpk_test_2j11LyHySUOPjt3fw2ZsdQEK",
    zpk_prod:"zpk_prod_XvZU7NSVyoVgbMFoXQbnvxRo",
    marketplace_id_sandbox:"607b53fed8f144378e579fceb7a6b2a7",
    marketplace_id_prod:"a79d743d3d1745439a94106464d0fbed",
    seller_id_sandbox:"eeb645f99f8a4097bb7b0c5f06e57059",
    seller_id_prod:"4e712d55199d4429bca7d59fc8b4939c"
};