import React from 'react'
import { Grid, Button, Typography, Card, Box, Stack } from '@mui/material';
import Config from '../config/config';
import "./CardProducts.css";
import RowSimple from './RowSimple';
const numeral = require('numeral');


const CardProduct = ({ product, addToCart, verProdutoProps }) => {

    const VerificaImagens = () => {
        if (product.images.length === 0) {
            if (product.base64) {
                return <img src={`data:image/jpeg;base64, ${product.base64}`}  />
            } else {
                return <img src={`https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg`} style={{ padding: 10, height:250 }} />;
            }

        } else if (product.images.length > 1) {
            return <img
                style={{ maxHeight: 200 }}
                src={`${Config.base_fotos}/thumb/${product.produto}_${product.cor_produto}_02.jpg`}
                class="card-img-top"
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "https://katuxa.sfo3.digitaloceanspaces.com/pdv/foto_tratamento.jpg";
                }}
            />
        } else {
            return <img src={`https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg`}  style={{ padding: 10, height:250 }} />;
        }

    }



    return (
        <Card elevation={1} style={{ padding: 10, minHeight: 300 }} onClick={verProdutoProps}>
            <Stack direction={"column"} spacing={2}>
                <Box style={{ maxHeight: 150, minHeight:200 }}>
                    <VerificaImagens />
                </Box>
                <Stack direction={"column"} spacing={2}>
                    <Typography sx={{fontSize:12}} variant='subtitle1'>{product.use_descricao}</Typography>
                    <Stack spacing={1} direction="column">
                        <RowSimple
                            label={"REFERENCIA"}
                            value={product.use_referencia}
                        />
                        <RowSimple
                            label={"Cod cor"}
                            value={product.use_cod_cor_produto}
                        />
                                <RowSimple
                            label={"Cor"}
                            value={product.use_cor_produto}
                        />
                    </Stack>
                </Stack>

            </Stack>

        </Card>
    )
}

export default CardProduct
